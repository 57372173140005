import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/containers/layout';

const NotFoundPage = () => {
  if (typeof window === 'undefined') return null;

  return (
    <Layout>
      <SEO title="Page not found" />
      <h3>404: Page not found</h3>
    </Layout>
  );
};

export default NotFoundPage;
